import React from "react"
import styled from "@emotion/styled"
import { mq } from "../layouts/styles/theme"
import { zip } from "lodash"

const Wrapper = styled.div`
  max-width: 900px;
  margin-top: 4rem;

  img {
    width: 50%;
    ${mq[0]} {
      width: 33%;
    }
    ${mq[1]} {
      width: 25%;
    }
  }
`

const ImgDomino = ({ images, altTexts}) => {
  const data = zip(images, altTexts)
  return (
    <Wrapper>
      {data.map(([img, altText], idx) => {
        return <img key={idx} src={img} alt={altText} />
      })}
    </Wrapper>
  )
}

export default ImgDomino
