import React from "react"
import SEO from "../components/Seo"
import Container from "../components/Container"
import Belt from "../components/Belt"
import { Title } from "../components/typography"
import {
  Container as StaticBlockContainer,
  StaticBlock,
} from "../components/typography/StaticBlock"
import Map from "../components/Map"
import ImgDomino from "../components/ImgDomino"
import praxisSpiele_bw from "../images/belt/praxisSpiele_bw.jpg"
import praxisTisch from "../images/belt/praxisTisch.jpg"
import img1 from "../images/praxis/treppe.jpg"
import img2 from "../images/praxis/praxis.jpg"
import img3 from "../images/praxis/spiele.jpg"
import img4 from "../images/praxis/praxisDetail.jpg"
import img5 from "../images/praxis/eingang.jpg"
import white from "../images/praxis/white.jpg"

const PraxisPage = () => (
  <>
    <SEO
      title="Praxis"
      keywords={[
        "Kontakt",
        "Praxis am Park",
        "Stampfenbachstr.",
        "Zürich",
        "Termine",
        "Anfahrt",
        "Parkhaus",
        "Hauptbahnhof Zürich",
        "Höngg",
      ]}
      description="Die Praxis befindet sich in Zürich, in der Nähe des Hauptbahnhofes Zürich und ist mit den öffentlichen Verkehrsmittel sowie mit dem Auto gut erreichbar."
    />
    <Belt leftImage={praxisSpiele_bw} rightImage={praxisTisch} />
    <Container>
      <StaticBlockContainer>
        <StaticBlock>
          <Title>KONTAKT</Title>
          <p>
            Die Praxis liegt sehr zentral in der Nähe des Stampfenbachplatzes
            und 5 Minuten vom Hauptbahnhof Zürich.
          </p>
          <p>
            <br />
            Clelia Sasselli, Dipl. Hom. SkHZ
            <br />
            Praxis am Park
            <br />
            Stampfenbachstrasse 123
            <br />
            8006 Zürich
          </p>
          <p>
            076 492 50 20
            <br />
            praxis@homoeopathie-zueri.ch
          </p>
        </StaticBlock>
        <StaticBlock>
          <Title>TERMINE</Title>
          <p>Termine können telefonisch oder per E-Mail vereinbart werden.</p>
          <h3>Sprechstunde</h3>
          <p>
            Mittwoch: 9.00 - 18.30
            <br />
            Donnerstag: 9.00-18.30
          </p>
          <h3>Telefonische Konsultation</h3>
          <p>
            Montag: 14.00-17.00
            <br />
            Freitag: 14.00-17.00
          </p>
        </StaticBlock>
        <StaticBlock>
          <Title>PRAXIS</Title>
        </StaticBlock>
        <ImgDomino
          images={[img1, img2, white, img3, white, img4, img5, white]}
          altTexts={[
            "Predictive Praxis",
            "Clelia Sasselli",
            "weisses Bild",
            "Praful Vijayakar",
            "weisses Bild",
            "Clelia Sasselli",
            "Team Predictive Homeopathy",
            "weisses Bild",
          ]}
        />
        <StaticBlock>
          <Title>ANFAHRT</Title>
          <h3>Öffentliche Verkehrsmittel</h3>
          <p>
            Die Praxis ist nur 5 Minuten vom Hauptbahnhof Zürich entfernt und
            sehr gut mit den öffentlichen Verkehrsmitteln erreichbar.
          </p>
          <p>
            Die Praxis befindet sich neben der Haltestelle Beckenhof und ist mit
            den Tramlinien 11 und 14 erreichbar.
          </p>
          <p>
            Von Höngg aus fährt man mit der Busnummer 46 (Bahnhofquai/HB) bis
            zum Stampfenbachplatz und steigt dann für eine Station auf die
            Tramlinie 11 (Auzelg) oder 14 (Seebach) um.
          </p>
          <h3>Auto</h3>
          <p>
            In der Nähe der Praxis gibt es blaue Zone und Parkhäuser, u.a. das{" "}
            <a
              href="https://www.parking.ch/de/parkings/zuerich/parkhaus-stampfenbach"
              target="_blank"
              rel="noreferrer noopener"
            >
              Parkhaus Stampfenbach
            </a>
            ,&nbsp; das &nbsp;
            <a
              href="https://www.parkingzuerich.ch/parkhaeuser/alle-standorte/?tx_parkingzuerich_allparkingunits[id]=6649&tx_parkingzuerich_allparkingunits[action]=show&tx_parkingzuerich_allparkingunits[controller]=ParkingUnit"
              target="_blank"
              rel="noreferrer noopener"
            >
              Parkhaus Hauptbahnhof
            </a>
            &nbsp; und das &nbsp;
            <a
              href="https://parkingzuerich.ch/suchergebniss?tx_parkingzuerich_parkingunit%5Baction%5D=show&tx_parkingzuerich_parkingunit%5Bcontroller%5D=ParkingUnit&tx_parkingzuerich_parkingunit%5Bid%5D=6677&cHash=820a1eb4c453db20207ddda737536e43"
              target="_blank"
              rel="noreferrer noopener"
            >
              Parkhaus Central
            </a>
            .
          </p>
        </StaticBlock>
      </StaticBlockContainer>
      <Map />
    </Container>
  </>
)

export default PraxisPage
