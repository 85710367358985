import React from "react"
import styled from "@emotion/styled"
import GoogleMapReact from "google-map-react"

const positionMarker = { lat: 47.3848425, lng: 8.5397849 }
const mapCenter = { lat: 47.3839213, lng: 8.5364771 }

const renderMarker = (map, maps) => {
  new maps.Marker({
    position: positionMarker,
    map,
    title: "Homöopathie - Clelia Sasselli",
  })
}
const Container = styled.div`
  height: 500px;
  width: 100%;
`

const Map = (props) => {
  return (
    <Container>
      <GoogleMapReact
        center={mapCenter}
        zoom={15}
        bootstrapURLKeys={{ key: "AIzaSyCPWXZVrOH1PA7m1K7JF5GTyBO5ssfUqmo" }}
        onGoogleApiLoaded={({ map, maps }) => renderMarker(map, maps)}
      />
    </Container>
  )
}

export default Map
